import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceR5Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="R5"
          description="View route information and buy your ticket for the R5 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">R5 service</h1>
              <p>Lower Mickletown – Oulton Academy</p>
              <p>Oulton Academy – Oulton - Lower Mickletown</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="R5 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=119wuKgebAW5YA3nsiWbj_vYYc3Kktwo&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Oulton Academy
                </p>
                <p>
                  From Lower Mickletown, Pinfold Lane, Saville Road, Church Lane, Church Side, Watergate, Newmarket Lane, Wakefield Road, Pennington Lane, Royds School.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="R5 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1ZC0MRCC-owvoXVqTgWPgct21RGtngAQ&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Oulton Academy
                </p>
                <p>
                From Pennington Lane, Royds Lane, Marsh Street, Gillet Lane, Park Lane, Styebank Lane, Leeds Road, Holmsley Lane, North Lane, A639, Wakefield Road, Newmarket Lane, Watergate, Church Side, Church Lane, Saville Road, Pinfold Lane, Lower Mickletown.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Lower Mickletown</td>
                      <td>DEP</td>
                      <td>0757</td>
                    </tr>
                    <tr>
                      <td>Mickletown Methley School</td>
                      <td>DEP</td>
                      <td>0801</td>
                    </tr>
                    <tr>
                      <td>Watergate, Embleton Estate</td>
                      <td>DEP</td>
                      <td>0806</td>
                    </tr>
                    <tr>
                      <td>Scholey Hill</td>
                      <td>DEP</td>
                      <td>0810</td>
                    </tr>
                    <tr>
                      <td>Oulton Academy</td>
                      <td>ARR</td>
                      <td>0815</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Oulton Academy</td>
                      <td>DEP</td>
                      <td>1440</td>
                    </tr>
                    <tr>
                      <td>John O’ Gaunts</td>
                      <td>DEP</td>
                      <td>1445</td>
                    </tr>
                    <tr>
                      <td>North Lane</td>
                      <td>DEP</td>
                      <td>1450</td>
                    </tr>
                    <tr>
                      <td>Scholey Hill</td>
                      <td>DEP</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>Watergate, Embleton Estate</td>
                      <td>DEP</td>
                      <td>1504</td>
                    </tr>
                    <tr>
                      <td>Lower Mickletown</td>
                      <td>ARR</td>
                      <td>1513</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceR5Page
